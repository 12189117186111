import {Dispatch, SetStateAction, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {Checkbox, Grid, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';
import {RateData} from '../../templates/Rate';
import {usePut} from '../../../hooks/usePut';

interface RateDataForApiRequest {
  id: number;
  ym: number;
  umj: string;
  maples: string;
  citco: string;
  ifs: string;
  apex: string;
  apex2: string;
  nenkin: string;
  abs: string;
  msf: string;
}

const yupRateRules = yup
  .string()
  .matches(/(^[0-9]+(\.[0-9]+)?$|^$)/, '半角数字で入力してください。')
  .max(16, '小数点以下は、12桁以内で入力してください');

const schema = yup.object({
  umj: yupRateRules,
  maples: yupRateRules,
  citco: yupRateRules,
  ifs: yupRateRules,
  apex: yupRateRules,
  apex2: yupRateRules,
  nenkin: yupRateRules,
  abs: yupRateRules,
  msf: yupRateRules
});

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  rowInfo: RateData;
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export default function EditRateDialog(props: Props) {
  const {open, setOpen, rowInfo, render, setRender, setResMsg, setIsSuccess} = props;

  const [checked, setChecked] = useState(false);
  const [umj, setUmj] = useState<string | null>(null);
  const [maples, setMaples] = useState<string | null>(null);
  const [citco, setCitco] = useState<string | null>(null);
  const [ifs, setIfs] = useState<string | null>(null);
  const [apex, setApex] = useState<string | null>(null);
  const [apex2, setApex2] = useState<string | null>(null);
  const [nenkin, setNenkin] = useState<string | null>(null);
  const [abs, setAbs] = useState<string | null>(null);
  const [msf, setMsf] = useState<string | null>(null);

  const resetValue = () => {
    setChecked(false);
    setUmj(null);
    setMaples(null);
    setCitco(null);
    setIfs(null);
    setApex(null);
    setApex2(null);
    setNenkin(null);
    setAbs(null);
    setMsf(null);
  };

  const handleClose = () => {
    reset();
    resetValue();
    setOpen(false);
  };

  const {
    control,
    handleSubmit,
    formState: {errors},
    reset
  } = useForm<RateDataForApiRequest>({
    resolver: yupResolver(schema)
  });

  const {putRequest} = usePut('/rate', rowInfo.id, setResMsg, setRender, render, setIsSuccess, handleClose);

  const onSubmit: SubmitHandler<RateDataForApiRequest> = async data => {
    const body = JSON.stringify({
      id: data.id,
      ym: data.ym,
      // 空文字だった場合、0.0でリクエスト
      umj: data.umj === '' ? '0.0' : data.umj,
      maples: data.maples === '' ? '0.0' : data.maples,
      citco: data.citco === '' ? '0.0' : data.citco,
      ifs: data.ifs === '' ? '0.0' : data.ifs,
      apex: data.apex === '' ? '0.0' : data.apex,
      apex2: data.apex2 === '' ? '0.0' : data.apex2,
      nenkin: data.nenkin === '' ? '0.0' : data.nenkin,
      abs: data.abs === '' ? '0.0' : data.abs,
      msf: data.msf === '' ? '0.0' : data.msf
    });

    await putRequest(body);
  };

  // 各項目に変更があるか否かを示すフラグを定義
  const isChangedUmj = umj !== null && umj !== rowInfo.umj;
  const isChangedMaples = maples !== null && maples !== rowInfo.maples;
  const isChangedCitco = citco !== null && citco !== rowInfo.citco;
  const isChangedIfs = ifs !== null && ifs !== rowInfo.ifs;
  const isChangedApex = apex !== null && apex !== rowInfo.apex;
  const isChangedApex2 = apex2 !== null && apex2 !== rowInfo.apex2;
  const isChangedNenkin = nenkin !== null && nenkin !== rowInfo.nenkin;
  const isChangedAbs = abs !== null && abs !== rowInfo.abs;
  const isChangedMsf = msf !== null && msf !== rowInfo.msf;

  // 全項目通して変更があるか否かのフラグを定義
  const isChanged =
    isChangedUmj ||
    isChangedMaples ||
    isChangedCitco ||
    isChangedIfs ||
    isChangedApex ||
    isChangedApex2 ||
    isChangedNenkin ||
    isChangedAbs ||
    isChangedMsf;

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <Stack
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>対象年月：{rowInfo.ym}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{mb: 2}}>レート情報を編集してください。</DialogContentText>
          <Grid container my={4} justifyContent="space-between">
            <Grid item xs={5}>
              <Controller
                name="umj"
                control={control}
                render={({field}) => (
                  <TextField
                    sx={{mb: 1}}
                    label="UMJ"
                    fullWidth
                    variant="standard"
                    defaultValue={rowInfo.umj}
                    error={Boolean(errors.umj)}
                    helperText={errors.umj?.message}
                    onChange={e => {
                      field.onChange(e.target.value);
                      setUmj(e.target.value);
                      setChecked(false);
                    }}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Controller
                name="maples"
                control={control}
                render={({field}) => (
                  <TextField
                    sx={{mb: 1}}
                    label="MAPLES"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={rowInfo.maples}
                    error={'maples' in errors}
                    helperText={errors.maples?.message}
                    onChange={e => {
                      field.onChange(e.target.value);
                      setMaples(e.target.value);
                      setChecked(false);
                    }}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="space-between">
            <Grid item xs={5}>
              <Controller
                name="citco"
                control={control}
                render={({field}) => (
                  <TextField
                    sx={{mb: 1}}
                    label="CITCO"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={rowInfo.citco}
                    error={'citco' in errors}
                    helperText={errors.citco?.message}
                    onChange={e => {
                      field.onChange(e.target.value);
                      setCitco(e.target.value);
                      setChecked(false);
                    }}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Controller
                name="ifs"
                control={control}
                render={({field}) => (
                  <TextField
                    sx={{mb: 1}}
                    label="IFS"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={rowInfo.ifs}
                    error={'ifs' in errors}
                    helperText={errors.ifs?.message}
                    onChange={e => {
                      field.onChange(e.target.value);
                      setIfs(e.target.value);
                      setChecked(false);
                    }}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="space-between">
            <Grid item xs={5}>
              <Controller
                name="apex"
                control={control}
                render={({field}) => (
                  <TextField
                    sx={{mb: 1}}
                    label="APEX"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={rowInfo.apex}
                    error={'apex' in errors}
                    helperText={errors.apex?.message}
                    onChange={e => {
                      field.onChange(e.target.value);
                      setApex(e.target.value);
                      setChecked(false);
                    }}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Controller
                name="apex2"
                control={control}
                render={({field}) => (
                  <TextField
                    sx={{mb: 1}}
                    label="APEX2"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={rowInfo.apex2}
                    error={'apex2' in errors}
                    helperText={errors.apex2?.message}
                    onChange={e => {
                      field.onChange(e.target.value);
                      setApex2(e.target.value);
                      setChecked(false);
                    }}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Controller
                name="nenkin"
                control={control}
                render={({field}) => (
                  <TextField
                    sx={{mb: 1}}
                    label="NENKIN"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={rowInfo.nenkin}
                    error={'nenkin' in errors}
                    helperText={errors.nenkin?.message}
                    onChange={e => {
                      field.onChange(e.target.value);
                      setNenkin(e.target.value);
                      setChecked(false);
                    }}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="space-between">
            <Grid item xs={5}>
              <Controller
                name="abs"
                control={control}
                render={({field}) => (
                  <TextField
                    sx={{mb: 1}}
                    label="ABS"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={rowInfo.abs}
                    error={'abs' in errors}
                    helperText={errors.abs?.message}
                    onChange={e => {
                      field.onChange(e.target.value);
                      setAbs(e.target.value);
                      setChecked(false);
                    }}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="space-between">
            <Grid item xs={5}>
              <Controller
                name="msf"
                control={control}
                render={({field}) => (
                  <TextField
                    sx={{mb: 1}}
                    label="MSF"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={rowInfo.msf}
                    error={'msf' in errors}
                    helperText={errors.msf?.message}
                    onChange={e => {
                      field.onChange(e.target.value);
                      setMsf(e.target.value);
                      setChecked(false);
                    }}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
          </Grid>
          {isChanged ? (
            <>
              <Grid container my={2} justifyContent="center">
                <Paper sx={{minWidth: 500, maxWidth: 700}}>
                  <Table sx={{width: '90%', mx: 'auto'}} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>項目</TableCell>
                        <TableCell>変更前</TableCell>
                        <TableCell>変更後</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isChangedUmj ? (
                        <TableRow>
                          <TableCell>UMJ</TableCell>
                          <TableCell>{rowInfo.umj}</TableCell>
                          <TableCell>{umj}</TableCell>
                        </TableRow>
                      ) : (
                        ''
                      )}
                      {isChangedMaples ? (
                        <TableRow>
                          <TableCell>MAPLES</TableCell>
                          <TableCell>{rowInfo.maples}</TableCell>
                          <TableCell>{maples}</TableCell>
                        </TableRow>
                      ) : (
                        ''
                      )}
                      {isChangedCitco ? (
                        <TableRow>
                          <TableCell>CITOCO</TableCell>
                          <TableCell>{rowInfo.citco}</TableCell>
                          <TableCell>{citco}</TableCell>
                        </TableRow>
                      ) : (
                        ''
                      )}
                      {isChangedIfs ? (
                        <TableRow>
                          <TableCell>IFS</TableCell>
                          <TableCell>{rowInfo.ifs}</TableCell>
                          <TableCell>{ifs}</TableCell>
                        </TableRow>
                      ) : (
                        ''
                      )}
                      {isChangedApex ? (
                        <TableRow>
                          <TableCell>APEX</TableCell>
                          <TableCell>{rowInfo.apex}</TableCell>
                          <TableCell>{apex}</TableCell>
                        </TableRow>
                      ) : (
                        ''
                      )}
                      {isChangedApex2 ? (
                        <TableRow>
                          <TableCell>APEX2</TableCell>
                          <TableCell>{rowInfo.apex2}</TableCell>
                          <TableCell>{apex2}</TableCell>
                        </TableRow>
                      ) : (
                        ''
                      )}
                      {isChangedNenkin ? (
                        <TableRow>
                          <TableCell>NENKIN</TableCell>
                          <TableCell>{rowInfo.nenkin}</TableCell>
                          <TableCell>{nenkin}</TableCell>
                        </TableRow>
                      ) : (
                        ''
                      )}
                      {isChangedAbs ? (
                        <TableRow>
                          <TableCell>ABS</TableCell>
                          <TableCell>{rowInfo.abs}</TableCell>
                          <TableCell>{abs}</TableCell>
                        </TableRow>
                      ) : (
                        ''
                      )}
                      {isChangedMsf ? (
                        <TableRow>
                          <TableCell>MSF</TableCell>
                          <TableCell>{rowInfo.msf}</TableCell>
                          <TableCell>{msf}</TableCell>
                        </TableRow>
                      ) : (
                        ''
                      )}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
              <Grid container my={2} justifyContent="center">
                <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
                <Typography sx={{m: 1}} variant="body1">
                  変更内容を確認
                </Typography>
              </Grid>
            </>
          ) : (
            ''
          )}
        </DialogContent>
        <Grid container justifyContent="space-around" my={1}>
          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button type="submit" disabled={!checked || !isChanged}>
              更新
            </Button>
          </DialogActions>
        </Grid>
      </Stack>
    </Dialog>
  );
}
