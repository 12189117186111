/* eslint-disable react-hooks/exhaustive-deps */
import {useState} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import {RateBar} from '../molecules/rate/RateBar';
import EditRateDialog from '../molecules/rate/EditRateDialog';
import AlertSnackbars from '../molecules/common/AlertSnackBar';
import {useFetch} from '../../hooks/useFetch';

export interface RateData {
  id: number;
  ym: number;
  umj: string;
  maples: string;
  citco: string;
  ifs: string;
  apex: string;
  apex2: string;
  nenkin: string;
  abs: string;
  msf: string;
}

const emptyRateRow: RateData = {
  id: 0,
  ym: 0,
  umj: '',
  maples: '',
  citco: '',
  ifs: '',
  apex: '',
  apex2: '',
  nenkin: '',
  abs: '',
  msf: ''
};

interface HeadCell {
  id: string;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'ym',
    label: 'Year and Month'
  },
  {
    id: 'umj',
    label: 'UMJ'
  },
  {
    id: 'maples',
    label: 'MAPLES'
  },
  {
    id: 'citco',
    label: 'CITCO'
  },
  {
    id: 'ifs',
    label: 'IFS'
  },
  {
    id: 'apex',
    label: 'APEX'
  },
  {
    id: 'apex2',
    label: 'APEX2'
  },
  {
    id: 'nenkin',
    label: 'NENKIN'
  },
  {
    id: 'abs',
    label: 'ABS'
  },
  {
    id: 'msf',
    label: 'MSF'
  }
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell style={{fontWeight: 'bold'}} key={headCell.id} align="center">
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function RateTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [render, setRender] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowInfo, setRowInfo] = useState<RateData>(emptyRateRow);
  const [resMsg, setResMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  // renderを渡さないとレートの更新に応じて再レンダリングされない
  const {data: rates} = useFetch<RateData[]>(`/rates`, render, setIsSuccess, setResMsg);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rates.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rates.length) : 0;

  const showEditRateDialog = (rate: RateData) => {
    setOpen(prevState => !prevState);
    setRowInfo(rate);
  };

  return (
    <Box sx={{width: '90%', mx: 'auto'}}>
      <RateBar render={render} setRender={setRender} setIsSuccess={setIsSuccess} setResMsg={setResMsg} />
      <AlertSnackbars resMsg={resMsg} isSuccess={isSuccess} />
      <Paper sx={{width: '100%', mb: 2}}>
        <TableContainer>
          <Table sx={{minWidth: 500}} aria-labelledby="tableTitle">
            <EnhancedTableHead />
            <TableBody>
              {rates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(rate => (
                <TableRow hover tabIndex={-1} onClick={() => showEditRateDialog(rate)} key={rate.id}>
                  <TableCell align="center">{rate.ym} </TableCell>
                  <TableCell align="center">
                    {rate.umj ? Number(rate.umj).toFixed(2) : <span style={{color: 'red'}}>未入力</span>}
                  </TableCell>
                  <TableCell align="center">
                    {rate.maples ? Number(rate.maples).toFixed(2) : <span style={{color: 'red'}}>未入力</span>}
                  </TableCell>
                  <TableCell align="center">
                    {rate.citco ? Number(rate.citco).toFixed(2) : <span style={{color: 'red'}}>未入力</span>}
                  </TableCell>
                  <TableCell align="center">
                    {rate.ifs ? Number(rate.ifs).toFixed(2) : <span style={{color: 'red'}}>未入力</span>}
                  </TableCell>
                  <TableCell align="center">
                    {rate.apex ? Number(rate.apex).toFixed(2) : <span style={{color: 'red'}}>未入力</span>}
                  </TableCell>
                  <TableCell align="center">
                    {rate.apex2 ? Number(rate.apex2).toFixed(2) : <span style={{color: 'red'}}>未入力</span>}
                  </TableCell>
                  <TableCell align="center">
                    {rate.nenkin ? Number(rate.nenkin).toFixed(2) : <span style={{color: 'red'}}>未入力</span>}
                  </TableCell>
                  <TableCell align="center">
                    {rate.abs ? Number(rate.abs).toFixed(2) : <span style={{color: 'red'}}>未入力</span>}
                  </TableCell>
                  <TableCell align="center">
                    {rate.msf ? Number(rate.msf).toFixed(2) : <span style={{color: 'red'}}>未入力</span>}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows
                  }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 30, 50]}
          component="div"
          count={rates.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <EditRateDialog
        open={open}
        setOpen={setOpen}
        rowInfo={rowInfo}
        render={render}
        setRender={setRender}
        setResMsg={setResMsg}
        setIsSuccess={setIsSuccess}
      />
    </Box>
  );
}
