import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import ExcelJS from 'exceljs';
import dayjs from 'dayjs';
import {Dispatch, SetStateAction, useContext} from 'react';
import {RateData} from '../../templates/Rate';
import {SnackBarContext} from '../../../Context/SnackBarContext';
import {useGet} from '../../../hooks/useGet';

const columnsMapKeys = [
  {header: 'Year and Month', key: 'ym'},
  {header: 'UMJ', key: 'umj'},
  {header: 'MAPLES', key: 'maples'},
  {header: 'CITCO', key: 'citco'},
  {header: 'IFS', key: 'ifs'},
  {header: 'APEX', key: 'apex'},
  {header: 'APEX2', key: 'apex2'},
  {header: 'NENKIN', key: 'nenkin'},
  {header: 'ABS', key: 'abs'},
  {header: 'MSF', key: 'msf'}
];

interface Errs {
  setResMsg: Dispatch<SetStateAction<string>>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export default function ExportRatesExcelButton(props: Errs) {
  const {setResMsg, setIsSuccess} = props;
  const {showSnackBars, setShowSnackBars} = useContext(SnackBarContext);

  const {getRequest} = useGet<RateData[]>(`/rates`, setIsSuccess, setResMsg);

  const clickButtonAsync = async () => {
    try {
      // ワークブックを作成する
      const workbook = new ExcelJS.Workbook();

      // ワークシートを作成する
      const worksheet = workbook.addWorksheet('FX Rates', {});

      // カラムとJSONキーのマッピング
      worksheet.columns = columnsMapKeys;

      const rateData = await getRequest('');

      if (!rateData) {
        throw new Error('データが見つかりませんでした');
      }

      // データ挿入
      rateData.forEach((row: RateData) => worksheet.addRow(row));

      // ファイルを生成
      const uint8Array = await workbook.csv.writeBuffer();
      const blob = new Blob([uint8Array], {type: 'application/octet-binary'});

      // ダウンロード
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);

      const now = dayjs().format('YYYYMMDD');

      link.download = `FX_Rates_${now}.csv`;
      link.click();
    } catch (e) {
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${e}`);
      setShowSnackBars(!showSnackBars);
    }
  };

  return (
    <Button
      variant="text"
      color="primary"
      startIcon={<DownloadIcon />}
      sx={{mr: 5}}
      /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
      onClick={() => clickButtonAsync()}>
      CSV
    </Button>
  );
}
