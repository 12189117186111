import {DialogActions, Grid} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import {useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import Box from '@mui/material/Box';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {FundData} from '../../templates/Fund';
import {FundProps} from '../../molecules/fund/FundBar';
import {usePost} from '../../../hooks/usePost';
import {NameTableData} from '../../templates/TrusteeBank';

interface FundDataForApiRequest {
  id: number;
  code: string;
  name: string;
  contractDate: string;
  startingDate: string;
  closingDate: string;
  custodian1: NameTableData;
  custodian2: NameTableData;
  custodian3: NameTableData;
  administrator: string;
  custodian1AccountNumberMemo: string;
  custodian2AccountNumberMemo: string;
  custodian3AccountNumberMemo: string;
  administratorAccountNumber: string;
  accountingYear: string;
  currency: string;
  domicile: string;
  type: string;
  trustee: string;
  auditor: string;
  strategy: string;
  investmentManager: string;
  investmentAdvisor: string;
  managers: string;
  rateCompany: string;
  remarks: string;
}

// バリデーションルール
const schema = yup.object({
  code: yup
    .string()
    .required('入力は必須です')
    .matches(/^[A-Z0-9]*$/, '半角英字4文字で入力してください')
    .max(4, '半角英字4文字で入力してください')
    .min(4, '半角英字4文字で入力してください'),
  name: yup
    .string()
    .required('入力は必須です')
    .matches(/^[a-zA-Z0-9 -/:-@[-~]*$/, '半角英数字または半角記号で入力してください')
    .max(150, '150文字以内で入力してください'),
  administrator: yup
    .string()
    .matches(/^[a-zA-Z0-9 -/:-@[-~]*$/, '半角英数字または半角記号で入力してください')
    .max(150, '150文字以内で入力してください'),
  custodian1AccountNumberMemo: yup
    .string()
    .matches(/^[a-zA-Z0-9 -/:-@[-~\r\n]*$/, '半角英数字または半角記号で入力してください')
    .max(500, '500文字以内で入力してください'),
  custodian2AccountNumberMemo: yup
    .string()
    .matches(/^[a-zA-Z0-9 -/:-@[-~\r\n]*$/, '半角英数字または半角記号で入力してください')
    .max(500, '500文字以内で入力してください'),
  custodian3AccountNumberMemo: yup
    .string()
    .matches(/^[a-zA-Z0-9 -/:-@[-~\r\n]*$/, '半角英数字または半角記号で入力してください')
    .max(500, '500文字以内で入力してください'),
  administratorAccountNumber: yup
    .string()
    .matches(/^[a-zA-Z0-9 -/:-@[-~\r\n]*$/, '半角英数字または半角記号で入力してください')
    .max(500, '500文字以内で入力してください'),
  domicile: yup
    .string()
    .nullable()
    .matches(/^[a-zA-Z0-9 -/:-@[-~]*$/, '半角英数字または半角記号で入力してください')
    .max(100, '100文字以内で入力してください'),
  type: yup
    .string()
    .nullable()
    .matches(/^[a-zA-Z0-9 -/:-@[-~]*$/, '半角英数字または半角記号で入力してください')
    .max(100, '100文字以内で入力してください'),
  trustee: yup
    .string()
    .nullable()
    .matches(/^[a-zA-Z0-9 -/:-@[-~]*$/, '半角英数字または半角記号で入力してください')
    .max(100, '100文字以内で入力してください'),
  auditor: yup
    .string()
    .nullable()
    .matches(/^[a-zA-Z0-9 -/:-@[-~]*$/, '半角英数字または半角記号で入力してください')
    .max(100, '100文字以内で入力してください'),
  strategy: yup
    .string()
    .nullable()
    .matches(/^[a-zA-Z0-9 -/:-@[-~]*$/, '半角英数字または半角記号で入力してください')
    .max(100, '100文字以内で入力してください'),
  investmentManager: yup
    .string()
    .nullable()
    .matches(/^[a-zA-Z0-9 -/:-@[-~]*$/, '半角英数字または半角記号で入力してください')
    .max(100, '100文字以内で入力してください'),
  investmentAdvisor: yup
    .string()
    .nullable()
    .matches(/^[a-zA-Z0-9 -/:-@[-~]*$/, '半角英数字または半角記号で入力してください')
    .max(100, '100文字以内で入力してください'),
  managers: yup
    .string()
    .nullable()
    .matches(/^[a-zA-Z0-9 -/:-@[-~]*$/, '半角英数字または半角記号で入力してください')
    .max(200, '200文字以内で入力してください')
});

export function AddFundDialog(props: FundProps) {
  const {funds, custodians, render, setRender, setResMsg, setIsSuccess} = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    reset();
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  // ここでdefaultValuesを設定できる → 編集画面に使えそう
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors}
  } = useForm<FundDataForApiRequest>({
    resolver: yupResolver(schema)
  });

  //
  // サジェストで表示するための配列を準備
  //

  const nameArray: string[] = [];
  const administratorArray: string[] = [];
  const domicileArray: string[] = [];
  const typeArray: string[] = [];
  const trusteeArray: string[] = [];
  const auditorArray: string[] = [];
  const strategyArray: string[] = [];
  const investManagerArray: string[] = [];
  const investAdvisorArray: string[] = [];
  const managersArray: string[] = [];

  funds.forEach((row: FundData) => {
    nameArray.push(row.name);
    domicileArray.push(row.domicile);
    administratorArray.push(row.administrator);
    typeArray.push(row.type);
    trusteeArray.push(row.trustee);
    auditorArray.push(row.auditor);
    strategyArray.push(row.strategy);
    investManagerArray.push(row.investmentManager);
    investAdvisorArray.push(row.investmentAdvisor);
    managersArray.push(row.managers);
  });

  // 重複、空文字を除外して配列を作成
  const suggestingNames = nameArray.filter((x, i, self) => (x !== '' ? self.indexOf(x) === i : ''));

  const suggestingAdministrator = administratorArray.filter((x, i, self) => (x !== '' ? self.indexOf(x) === i : ''));

  const suggestingDomicile = domicileArray.filter((x, i, self) => (x !== '' ? self.indexOf(x) === i : ''));

  const suggestingType = typeArray.filter((x, i, self) => (x !== '' ? self.indexOf(x) === i : ''));

  const suggestingTrustee = trusteeArray.filter((x, i, self) => (x !== '' ? self.indexOf(x) === i : ''));

  const suggestingAuditor = auditorArray.filter((x, i, self) => (x !== '' ? self.indexOf(x) === i : ''));

  const suggestingStrategy = strategyArray.filter((x, i, self) => (x !== '' ? self.indexOf(x) === i : ''));

  const suggestingInvestManager = investManagerArray.filter((x, i, self) => (x !== '' ? self.indexOf(x) === i : ''));

  const suggestingInvestAdvisor = investAdvisorArray.filter((x, i, self) => (x !== '' ? self.indexOf(x) === i : ''));

  const suggestingManagers = managersArray.filter((x, i, self) => (x !== '' ? self.indexOf(x) === i : ''));

  const {postRequest} = usePost('/fund', setResMsg, setRender, render, setIsSuccess, setOpen);

  const onSubmit: SubmitHandler<FundDataForApiRequest> = async data => {
    const body = JSON.stringify({
      code: data.code,
      name: data.name,
      contractDate: data.contractDate || null, // 一度フィールドに入力して取り消した状態でsubmitされると、ここで空文字になってしまうのでnullに戻してリクエストしたい
      startingDate: data.startingDate || null, // 一度フィールドに入力して取り消した状態でsubmitされると、ここで空文字になってしまうのでnullに戻してリクエストしたい
      closingDate: data.closingDate || null, // 一度フィールドに入力して取り消した状態でsubmitされると、ここで空文字になってしまうのでnullに戻してリクエストしたい
      administrator: data.administrator || '',
      administratorAccountNumber: data.administratorAccountNumber || '',
      accountingYear: data.accountingYear || '',
      currency: data.currency || '',
      domicile: data.domicile || '',
      type: data.type || '',
      trustee: data.trustee || '',
      auditor: data.auditor || '',
      strategy: data.strategy ? data.strategy : '',
      investmentManager: data.investmentManager || '',
      investmentAdvisor: data.investmentAdvisor || '',
      managers: data.managers || '',
      rateCompany: data.rateCompany || '',
      custodian1Id: data.custodian1?.id || 0, // IDをリクエストJSONに格納
      custodian2Id: data.custodian2?.id || 0, // IDをリクエストJSONに格納
      custodian3Id: data.custodian3?.id || 0, // IDをリクエストJSONに格納
      custodian1AccountNumberMemo: data.custodian1AccountNumberMemo || '',
      custodian2AccountNumberMemo: data.custodian2AccountNumberMemo || '',
      custodian3AccountNumberMemo: data.custodian3AccountNumberMemo || '',
      remarks: data.remarks || ''
    });

    await postRequest(body);
  };

  return (
    <div>
      <Button variant="text" onClick={handleClickOpen} startIcon={<AddCircleOutlineOutlinedIcon />}>
        新規登録
      </Button>
      <Dialog maxWidth="xl" fullWidth open={open} onClose={handleClose}>
        <Stack
          component="form"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}>
          <Grid container mb={1} justifyContent="space-between" alignItems="baseline">
            <DialogTitle>新規登録</DialogTitle>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <DialogContent>
            <Grid container mb={4} justifyContent="flex-start">
              <Grid mx={2} sx={{width: 200}}>
                <Controller
                  name="code"
                  control={control}
                  render={({field}) => (
                    <TextField
                      // サジェストなし自由入力(A)
                      label="Fund Code"
                      variant="standard"
                      error={Boolean(errors.code)}
                      helperText={errors.code?.message}
                      autoComplete="off"
                      onChange={e => field.onChange(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{width: 500}}>
                <Controller
                  name="name"
                  control={control}
                  render={({field}) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingNames}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={params => (
                        <TextField
                          label="Fund Name"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.name)}
                          helperText={errors.name?.message}
                          onChange={e => field.onChange(e.target.value)}
                          // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更
                          onBlur={e => field.onChange(e.target.value)}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container mb={4} justifyContent="flex-start">
              <Grid mx={2} sx={{width: 300}}>
                <Stack>
                  <Controller
                    name="contractDate"
                    control={control}
                    render={({field}) => (
                      <TextField
                        // サジェストなし自由入力(A)
                        label="Contract Date"
                        variant="standard"
                        type="date"
                        InputLabelProps={{shrink: true}}
                        onChange={e => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid mx={2} sx={{width: 300}}>
                <Stack>
                  <Controller
                    name="startingDate"
                    control={control}
                    render={({field}) => (
                      <TextField
                        // サジェストなし自由入力(A)
                        label="Starting Date"
                        variant="standard"
                        type="date"
                        InputLabelProps={{shrink: true}}
                        onChange={e => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid mx={2} sx={{width: 300}}>
                <Stack>
                  <Controller
                    name="closingDate"
                    control={control}
                    render={({field}) => (
                      <TextField
                        // サジェストなし自由入力(A)
                        label="Closing Date"
                        variant="standard"
                        type="date"
                        InputLabelProps={{shrink: true}}
                        onChange={e => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid mx={2} sx={{width: 300}}>
                <Controller
                  name="custodian1"
                  control={control}
                  render={({field}) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={custodians}
                      getOptionLabel={custodian => custodian.name}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={params => (
                        <TextField
                          label="PB/Custodian1"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{width: 300}}>
                <Controller
                  name="custodian2"
                  control={control}
                  render={({field}) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={custodians}
                      getOptionLabel={custodian => custodian.name}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={params => (
                        <TextField
                          label="PB/Custodian2"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{width: 300}}>
                <Controller
                  name="custodian3"
                  control={control}
                  render={({field}) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={custodians}
                      getOptionLabel={custodian => custodian.name}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={params => (
                        <TextField
                          label="PB/Custodian3"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{width: 300}}>
                <Controller
                  name="administrator"
                  control={control}
                  render={({field}) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingAdministrator}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={params => (
                        <TextField
                          label="Administrator"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.administrator)}
                          helperText={errors.administrator?.message}
                          onChange={e => field.onChange(e.target.value)}
                          // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更
                          onBlur={e => field.onChange(e.target.value)}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid mx={2} sx={{width: 300}}>
                <Stack>
                  <Controller
                    name="custodian1AccountNumberMemo"
                    control={control}
                    render={({field}) => (
                      <TextField
                        // サジェストなし自由入力(A)
                        id="standard-multiline-static"
                        label="PB/Custodian1 Account Number"
                        multiline
                        rows={3}
                        variant="standard"
                        error={Boolean(errors.custodian1AccountNumberMemo)}
                        helperText={errors.custodian1AccountNumberMemo?.message}
                        autoComplete="off"
                        onChange={e => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid mx={2} sx={{width: 300}}>
                <Stack>
                  <Controller
                    name="custodian2AccountNumberMemo"
                    control={control}
                    render={({field}) => (
                      <TextField
                        // サジェストなし自由入力(A)
                        id="standard-multiline-static"
                        label="PB/Custodian2 Account Number"
                        multiline
                        rows={3}
                        variant="standard"
                        error={Boolean(errors.custodian2AccountNumberMemo)}
                        helperText={errors.custodian2AccountNumberMemo?.message}
                        autoComplete="off"
                        onChange={e => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid mx={2} sx={{width: 300}}>
                <Stack>
                  <Controller
                    name="custodian3AccountNumberMemo"
                    control={control}
                    render={({field}) => (
                      <TextField
                        // サジェストなし自由入力(A)
                        id="standard-multiline-static"
                        label="PB/Custodian3 Account Number"
                        multiline
                        rows={3}
                        variant="standard"
                        error={Boolean(errors.custodian3AccountNumberMemo)}
                        helperText={errors.custodian3AccountNumberMemo?.message}
                        autoComplete="off"
                        onChange={e => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid mx={2} sx={{width: 300}}>
                <Stack>
                  <Controller
                    name="administratorAccountNumber"
                    control={control}
                    render={({field}) => (
                      <TextField
                        // サジェストなし自由入力(A)
                        id="standard-multiline-static"
                        label="Administrator Account Number"
                        multiline
                        rows={3}
                        variant="standard"
                        error={Boolean(errors.administratorAccountNumber)}
                        helperText={errors.administratorAccountNumber?.message}
                        autoComplete="off"
                        onChange={e => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid mx={2} sx={{width: 200}}>
                <Controller
                  name="accountingYear"
                  control={control}
                  render={({field}) => (
                    <Autocomplete
                      // 選択肢入力(D)
                      options={[
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December'
                      ]}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={params => (
                        <TextField
                          label="Fund Accounting Year"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.accountingYear)}
                          helperText={errors.accountingYear?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{width: 200}}>
                <Controller
                  name="currency"
                  control={control}
                  render={({field}) => (
                    <Autocomplete
                      // 選択肢入力(D)
                      options={['USD', 'JPY']}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={params => (
                        <TextField
                          label="Fund Base Currency"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{width: 300}}>
                <Controller
                  name="domicile"
                  control={control}
                  render={({field}) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingDomicile}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={params => (
                        <TextField
                          label="Fund Domicile"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.domicile)}
                          helperText={errors.domicile?.message}
                          onChange={e => field.onChange(e.target.value)}
                          // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更
                          onBlur={e => field.onChange(e.target.value)}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{width: 300}}>
                <Controller
                  name="type"
                  control={control}
                  render={({field}) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingType}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={params => (
                        <TextField
                          label="Fund Type"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.type)}
                          helperText={errors.type?.message}
                          onChange={e => field.onChange(e.target.value)}
                          // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更
                          onBlur={e => field.onChange(e.target.value)}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid mx={2} sx={{width: 300}}>
                <Controller
                  name="trustee"
                  control={control}
                  render={({field}) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingTrustee}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={params => (
                        <TextField
                          label="Fund Trustee"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.trustee)}
                          helperText={errors.trustee?.message}
                          onChange={e => field.onChange(e.target.value)}
                          // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更
                          onBlur={e => field.onChange(e.target.value)}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{width: 300}}>
                <Controller
                  name="auditor"
                  control={control}
                  render={({field}) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingAuditor}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={params => (
                        <TextField
                          label="Fund Auditor"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.auditor)}
                          helperText={errors.auditor?.message}
                          onChange={e => field.onChange(e.target.value)}
                          // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更
                          onBlur={e => field.onChange(e.target.value)}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{width: 300}}>
                <Controller
                  name="strategy"
                  control={control}
                  render={({field}) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingStrategy}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={params => (
                        <TextField
                          label="Fund Strategy"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.strategy)}
                          helperText={errors.strategy?.message}
                          onChange={e => field.onChange(e.target.value)}
                          // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更
                          onBlur={e => field.onChange(e.target.value)}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid mx={2} sx={{width: 300}}>
                <Controller
                  name="investmentManager"
                  control={control}
                  render={({field}) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingInvestManager}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={params => (
                        <TextField
                          label="Investment Manager"
                          variant="standard"
                          /* eslint-disable-next-line react/jsx-props-no-spreading */
                          {...params}
                          error={Boolean(errors.investmentManager)}
                          helperText={errors.investmentManager?.message}
                          onChange={e => field.onChange(e.target.value)}
                          // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更
                          onBlur={e => field.onChange(e.target.value)}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{width: 300}}>
                <Controller
                  name="investmentAdvisor"
                  control={control}
                  render={({field}) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingInvestAdvisor}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={params => (
                        <TextField
                          label="Investment Advisor"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.investmentAdvisor)}
                          helperText={errors.investmentAdvisor?.message}
                          onChange={e => field.onChange(e.target.value)}
                          // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更
                          onBlur={e => field.onChange(e.target.value)}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{width: 400}}>
                <Controller
                  name="managers"
                  control={control}
                  render={({field}) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingManagers}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={params => (
                        <TextField
                          label="Fund Manager"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.managers)}
                          helperText={errors.managers?.message}
                          onChange={e => field.onChange(e.target.value)}
                          // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更
                          onBlur={e => field.onChange(e.target.value)}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{width: 200}}>
                <Controller
                  name="rateCompany"
                  control={control}
                  render={({field}) => (
                    <Autocomplete
                      // 選択肢入力(D)
                      options={['UMJ', 'MAPLES', 'CITCO', 'IFS', 'APEX', 'APEX2', 'NENKIN', 'ABS', 'MSF']}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={params => (
                        <TextField
                          label="FX"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid item mx={2} xs={12}>
                <Box
                  sx={{
                    '& .MuiTextField-root': {width: '75%'}
                  }}>
                  <Controller
                    name="remarks"
                    control={control}
                    render={({field}) => (
                      <TextField
                        id="standard-multiline-static"
                        label="Remarks"
                        multiline
                        rows={4}
                        variant="standard"
                        onChange={e => field.onChange(e.target.value)}
                        autoComplete="off"
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <Grid container justifyContent="space-around" my={1}>
            <DialogActions>
              <Button onClick={handleClose}>キャンセル</Button>
              <Button type="submit">登録</Button>
            </DialogActions>
          </Grid>
        </Stack>
      </Dialog>
    </div>
  );
}
